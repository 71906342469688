
html{
  font-size: 16px;
  height: 100%;

    
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #000; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #28c4a4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}


